import { unref, Ref } from 'vue'

import some from 'lodash-es/some'
import { Improvement } from '@opteo/types'
import { improvementErrors, ErrorTypes } from '@/composition/improvement/errors'
import { Endpoint, authRequest } from '../api/useAPI'
import { useDomain } from '../domain/useDomain'
import { delay } from '@/lib/globalUtils'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { pushToDatalayer } from '@/lib/datalayer'

export type OnPushHandler<PushedData = {}> = () => {
    valid: boolean
    pushedData?: PushedData //ExtraDetails
}

type PushImprovementParams = {
    onPush?: OnPushHandler
}

const push_mode = 'api'

export function usePushImprovement() {
    const { domainId } = useDomain()
    const intercom = useIntercom()

    const pushImprovement = async ({
        improvementId,
        impParams,
        recAction,
        isInBatch = false,
    }: {
        improvementId: number
        impParams: PushImprovementParams
        recAction: Improvement.RecAction
        isInBatch?: boolean
    }) => {
        let body: null | Record<string, unknown> = {}

        if (typeof impParams.onPush !== 'undefined') {
            body = impParams.onPush()
        }

        try {
            if (push_mode === 'mock_success') {
                console.log('pushing with body: ', body)
                console.log('in development mode, not actually pushing')
                await delay(1000)
                return
            } else if (push_mode === 'mock_error') {
                console.log('pushing with body: ', body)
                console.log('in development mode, not actually pushing')
                await delay(1000)
                /*
                    TODO: more randomised error messages here
                */
                throw new Error('link_still_broken')
                //read_only_domain
            } else if (push_mode === 'api') {
                await authRequest(Endpoint.ImplementImprovement, {
                    body: {
                        domain_id: domainId.value,
                        improvement_id: improvementId,
                        extra_details: body?.pushedData,
                        in_batch: isInBatch,
                    },
                })
            } else {
                throw new Error('invalid IMP_PUSH_MODE')
            }
        } catch (error: any) {
            console.log('usePushImp catch', error)
            let errorMessage: any = ''
            some(Object.keys(improvementErrors), (snippet: ErrorTypes) => {
                if (error.message && error.message.includes(snippet)) {
                    if (
                        typeof improvementErrors[snippet]?.dynamicLong === 'function' &&
                        improvementErrors[snippet].dynamicLong
                    ) {
                        errorMessage = improvementErrors[snippet].dynamicLong!(error.message)
                    } else {
                        errorMessage = improvementErrors[snippet].long
                    }

                    throw new Error(errorMessage)
                }
            })

            throw new Error('Error Pushing Improvement')
        }

        pushToDatalayer('gtm_completed_improvement', {
            improvement: { rec_action: recAction },
        })

        intercom.trackEvent('completed_improvement', { rec_action: recAction })
    }

    return {
        pushImprovement,
    }
}

export function checkImprovement<T>(improvement: Ref<Improvement.Object<T> | undefined>) {
    const imp = unref(improvement)

    if (typeof imp === 'undefined') {
        throw new Error('Improvement not found.')
    }

    return imp
}
